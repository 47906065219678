import React from 'react';
import {Seo} from '../components/Seo';
import {graphql} from 'gatsby';
import PageBanner from '../components/PageBanner';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';

const PartnersPage = ({data}) => {
  const prismicData = data.prismicPartnershipsPage.data;
  const pageBannerData = {
    title: 'Partners',
    titleColor: '#ff6cbf',
    titleParts: prismicData.page_banner_title_parts.map(i => ({
      color: i.color,
      text: i.part,
      new_line: i.new_line
    })),
    paragraphParts: prismicData.page_banner_paragraphs.map(i => ([{
      color: i.color,
      text: i.part,
      new_line: i.new_line
    }])),
    captionParts: prismicData.page_banner_caption_parts.map(i => ({
      color: i.color,
      text: i.part,
      new_line: i.new_line
    })),
    backgroundColor: prismicData.page_banner_background_color,
    image: prismicData.page_banner_image,
    imageStyle: 'mx-auto max-w-[65%] lg:max-w-[85%]',
    titlePartsSize: 'text-5xl lg:text-6xl',
    captionPartSize: 'text-3xl lg:text-4xl'
  };

  return (
    <main>
      <PageBanner {...pageBannerData} />
      <section className="bg-[#f1f2f2] py-20">
        <div className="xl:container mx-auto px-4">
          <div className="flex flex-wrap gap-x-24 gap-y-16 items-center justify-center">
            {prismicData.body.map((i,k) => (
              <div className="items-center justify-center flex max-w-[190px] w-[30%] lg:w-full"
                key={k}>
                <GatsbyImage image={getImage(i.primary.logo_black)}
                  alt={i.primary.logo_black.alt || ''}
                  className="mx-auto max-w-[190px]" />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="py-24">
        <div className="xl:container mx-auto px-12">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-20 gap-y-20 items-start">
            {prismicData.body.map((i,k) => (
              <div className="items-center justify-center flex flex-col relative px-8 h-full"
                key={k}>
                <div className="absolute w-full border-2 border-black rounded-3xl top-[88px] lg:top-[104px] h-[calc(100%-120px)]"></div>
                <div className="rounded-full h-44 w-44 md:h-52 md:w-52 flex items-center justify-center mb-4 relative drop-shadow-md py-4 px-8"
                  style={{backgroundColor: i.primary.logo_background}}>
                  <GatsbyImage image={getImage(i.primary.logo)}
                    alt={i.primary.logo.alt || ''} />
                </div>
                <div className="text-center mb-16 mt-4 grow">
                  <h5 className="text-xl mb-4"
                    style={{color: i.primary.category_color}}>{i.primary.category}</h5>
                  <h4 className="font-black text-4xl mb-4">{i.primary.name}</h4>
                  <div dangerouslySetInnerHTML={{__html: i.primary.description.html}}
                    className="text-lg"></div>
                </div>
                <div className="relative w-full">
                <div className="absolute w-4 h-4 -top-2 rotate-45"
                  style={{
                    left: 'calc(50% - 0.5rem)',
                    backgroundColor: i.primary.category_color
                  }}></div>
                  <a href={i.primary.link.url}
                    rel="noreferrer noopener"
                    target="_blank"
                    style={{backgroundColor: i.primary.category_color}}
                    className="text-white font-black relative w-full rounded-xl inline-flex justify-center px-4 py-2 whitespace-nowrap">
                    FIND OUT MORE
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="bg-[#f8f8f8]">
        <div className="relative overflow-hidden w-full h-full py-24">
          <div className="relative xl:container mx-auto px-8">
            <div className="mx-auto max-w-[1000px]">
              <p className={clsx('font-black mb-8 text-5xl lg:text-6xl text-center')}>
                {prismicData.content_quote.map((i,k) => (
                  <span style={{color: i.color}}
                    key={k}
                    className={clsx(i.new_line && 'block')}>{i.part} </span>
                ))}
              </p>
              <p className="font-bold text-sourci-pink text-4xl text-right">
                - {prismicData.content_quote_author}
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export const query = graphql`
  query {
    prismicPartnershipsPage {
      data {
        content_quote_author
        content_quote {
          color
          new_line
          part
        }
        content_quote_author_color
        content_quote_banner_image {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        meta_description
        meta_title
        page_banner_background_color
        page_banner_caption_parts {
          color
          part
        }
        page_banner_image {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        page_banner_paragraphs {
          color
          new_line
          part {
            text
          }
        }
        page_banner_title_parts {
          color
          new_line
          part
        }
        body {
          ... on PrismicPartnershipsPageDataBodyPartnerBlock {
            id
            primary {
              category
              category_color
              name
              logo_background_2
              logo_background
              logo_black {
                alt
                gatsbyImageData(placeholder: BLURRED, height: 70, layout: CONSTRAINED)
              }
              logo {
                alt
                gatsbyImageData(placeholder: BLURRED, height: 140)
              }
              link {
                url
              }
              description {
                html
              }
            }
          }
        }
      }
    }
  }
`

export default PartnersPage

export function Head({data}) {
  const prismicData = data.prismicPartnershipsPage.data;
  return (
    <Seo title={prismicData.meta_title}
      description={prismicData.meta_description}
      canonical="/partnerships" />
  )
}